/**
 * Intro content
 **/
.intro{
    position: relative;
    top: -257px;

	.header-title,
	.col-sm-12{
		padding: 0;
	}

    h1{
		color: $white;
		font-size: 42px;
		font-weight: 500;
		letter-spacing: 0;
		text-shadow: 0 2px 60px rgba(0,0,0,0.50);
		margin-bottom: 45px;

		@include media-breakpoint-down(sm){
			font-size: 32px;
			margin-left: 35px;
		}
    }

    .intro-read-more{
    	display: none;
    }
}

.intro-content{
	background-color: $white;
    padding: 3.5rem 5rem;
	box-shadow: 0 -10px 25px 0 rgba(0,0,0,0.15);

	h2,
	h3{
		font-size: 30px;
		color: $dark-gray;
		letter-spacing: 0;
	}

	h2{
		font-weight: 300;
    	line-height: 32px;
    	font-size: 42px;
	}

	h3{
		color: #9D9E8E;
		font-weight: 500;
		position: relative;
		margin-bottom: 40px;
		font-size: 20px;

		&:after{
			position: absolute;
			content: '';
			background: $primary-color;
			border-radius: 0px;
			width: 103px;
			height: 3px;
    		left: 0;
    		bottom: -30px;
		}
	}

	p{
		max-width: 695px;
	}

	.btn-primary{
    	float: right;
    	position: relative;
    	top: -15px;

		@include media-breakpoint-down(sm){
			float: left;
			margin-top: 25px;
		}

		&.read-more{
			margin-right: 15px;
		}

	}

	@include media-breakpoint-down(sm){
		padding: 2.5rem 3rem;
	}
}

/**
 * Programma
**/
.programma{
    margin-top: -225px;
    padding-top: 4rem;
    padding-bottom: 4rem;

	&__content{
		padding-left: 0;
		padding-right: 0;

		p{
			max-width: 800px;
		}
	}

	h2{
		position: relative;
		margin-bottom: 75px;

		&:after{
			position: absolute;
			content: '';
			background-color: $secondary-color;
			border-radius: 0px;
			width: 103px;
			height: 3px;
    		left: 0;
    		bottom: -30px;
		}	
	}
	
	&__inner{
		@include media-breakpoint-down(sm){
			margin-left: 30px;			
		}	
	}	
}

/**
 * Tabs
**/
.tabs-wrapper{
	border: 1px solid #F2F2F2;
	box-shadow: 0 0 40px 0 rgba(0,0,0,0.10);

	.nav-tabs{
		border: 0;

		.nav-link{
		    padding: 1rem;
		    text-align: center;
		}
	}
	
	/* Dagen */
	.day-tabs{
		.first-day-tab{
			border-right: 1px solid rgba($secondary-color, 0.5);			
		}
	
		.nav-link{
		    background-color: rgba($secondary-color, 0.25);
			border: 0;
			border-bottom: 1px solid rgba($secondary-color, 0.5);

			color: $dark-gray;
			font-size: 24px;
			line-height: 28px;

			transition: background 0.3s ease-in-out;
			border-radius:0;

			span{
				color: $secondary-color;
				display: block;
				font-size: 16px;
    			font-weight: 700;
    			letter-spacing: -0.4px;
				line-height: 28px;
				text-transform: uppercase;
			}

			&:hover,
			&.active{
		  		background-color: rgba($secondary-color, 0.35);
			}
		}
	}

	/* Zalen */
	.place-tabs{
		.nav-link{
			border: 0;
			border-bottom: 1px solid rgba($secondary-color, 0.5);

			color: $dark-gray;
			font-size: 20px;
		}

		.nav-link{
			position: relative;
			transition: all 0.3s ease-in-out;

			&:after{
				content: '';
				position: absolute;
				background-color: rgba($secondary-color, 1);
				height: 1px;
				width: 100%;
				bottom: 0;
				left: 0;
				transition: all 0.2s ease-in-out;
    			//border-radius: 0px 3px 0px 0px;

			}

			&:hover,
			&.active{
				&:after{
					height: 3px;
				}
			}
		}	
	}
	
	/* Content */
	.tab-content-inner{
		padding: 2rem;
		padding-bottom: 0;

		.session{
			margin-bottom: 3rem;
		    padding-bottom: 3rem;
		    //border-bottom: 1px solid #f2f2f2;

			&__hour{
			    color: $secondary-color;
			    font-weight: 900;
			    letter-spacing: -0.4px;
			    font-size: 16px;
			    margin-bottom: 0;
			}

			&__title{
				color: $dark-gray;
			}

			&__intro{
				max-width: 520px
			}

			&__short-description{
				max-width: 960px;
			}

			&--single{
			    background-color: rgba($primary-color, 0.25);
			    padding: 5px;
			    padding-left: 15px;
			    border-bottom: 2px solid $primary-color;

			    p{
			    	text-transform: uppercase;
			    	font-weight: 900;
			    	font-size: 15px;
			    }			
			}

			.col-md-11{
				position: relative;
			}

			.session_inner{
    			border-bottom: 1px solid #cfece1;
    			padding-bottom: 12px;				
			}

			.more_content{
				font-size: 15px;
				color: #626262;
				letter-spacing: 0;
				line-height: 24px;	
				display: none;
			}

			.more_content__button{
				background-color: #cfece1;
				color: $primary-color;
				height: 25px;
				width: 25px;
    			line-height: 23px;
    			padding-left: 7px;
    			position: absolute;
    			right: 15px;
    			bottom: 0;
			}
		}
	}
}

/**
 * Call to action
 **/
.call-to-action{
	background-image: url('images/banner-CTA.png');
	background-size: cover;
	padding-top: 5rem;
	padding-bottom: 5rem;

	h3{
		font-size: 38px;
		font-weight: 700;
		color: $white;
		letter-spacing: 0;
		text-align: center;
		line-height: 42px;
		margin-bottom: 1.5rem;

		@include media-breakpoint-down(sm){
			font-size: 34px;
			line-height: 42px;			
		}
	}
}

/**
 * Partners
 **/
.partners{
	h2{
		position: relative;

		&:after{
			position: absolute;
			content: '';
			background-color: $secondary-color;
			border-radius: 0px;
			width: 103px;
			height: 3px;
    		left: 0;
    		bottom: -30px;
		}		
	}
	
	.logos{
		margin-top: 50px;
	}

	img{
		max-width: 100px;		
	}

	.logos a:not(:first-of-type){
		margin-left: 50px;
	}

	@include media-breakpoint-down(sm){
		padding-left: 30px;		
	}
}