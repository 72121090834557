/**
 * Footer
**/

.footer-wrap{
	background-color: #9D9E8E;
    padding-top: 2rem;
    padding-bottom: 2rem;

	p{
		color: #FFFFFF;		
	}

    a{
    	color: #FFFFFF;
    }

    .magelaan a{
    	color: #FFFFFF;
    }

    nav a{
    	margin-right: 15px;
    }
}