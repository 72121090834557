header{
	padding-top: 20px;
}

.header{
	background-image: url('images/header-ggzcongres2022.png');
	height: 470px;
    background-size: cover;

	@include media-breakpoint-down(sm) {
		height: 300px;
	}

	&--subpage{
		height: 300px;
		position: relative;

		.row{
			position: absolute;
		    bottom: 20px;
		    margin-left: 0;
		}

		h1{
			color: $white;
		}		
	}
}

.logo{
	max-width: 240px;
}


/**
 * Navbar
**/

.navbar-light .navbar-nav .nav-link{
	color: #292929;
	margin-left: 15px;

	&:hover,
	&:focus{
		color: #3EB489;
	}
}

.navbar{
	.btn{
		margin-left: 15px;

		@include media-breakpoint-up(md){
			margin-left: 25px;
		}
	}

	.btn .nav-link{
		color: $white;
		padding: 0;
		margin-left: 0;
		
		&:hover,
		&:focus,
		&:active{
			color: $white;
		}
	}	
}

@include media-breakpoint-down(sm) {
	.navbar-nav{
	    margin-bottom: 25px;
	    margin-right: auto !important;
	    margin-left: 0 !important;	
	}
}

/**
 * Mobile only
 **/

@include media-breakpoint-down(md) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: inherit !important;
		}
	}
} 