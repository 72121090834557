/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$primary-color: #3EB489;
$secondary-color: #3EB489;

$white: #FFFFFF;
$dark-gray: #292929;
$black: #000000;