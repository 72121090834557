/*
 * Typography
 */

 * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


img{
	max-width: 100%;
}

p{
	font-size: 15px;
	color: #626262;
	letter-spacing: 0;
	line-height: 24px;
}

.btn{
	box-shadow: 0 2px 20px 0 rgba(0,0,0,0.30);
	border-radius: 0px !important;
	border: 0;
	padding: 0.7rem 2rem;
	font-weight: 700;
	transition: all 0.3s ease-in-out;
	outline: none;

	&:focus,
	&:hover,
	&:active{
		border: 0;
		outline: none !important;
		box-shadow: 0 2px 20px 0 rgba(0,0,0,0.30);
	}
}

.btn-primary{
	background: $primary-color;
	transition: background-image 0.3s ease-in-out;

	&:hover{
		background: $primary-color;
	}

	&:focus,
	&:hover,
	&:active{
		background: rgba($primary-color, 0.75);
		border: 0;
		outline: none !important;
		box-shadow: 0 2px 20px 0 rgba($primary-color, 0.25) !important;
	}	
}

.btn-white{
	background-color: $white;
	color: #292929;

	&:hover{
		background-color: #f2f2f2;
	}
}